import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { saveAs } from "file-saver";
import {
  FaRegFilePdf,
  FaSpinner,
  FaCaretUp,
  FaCaretRight,
  FaTable,
  FaFile,
  FaChartPie,
  FaEdit,
  FaSave,
} from "react-icons/fa";
import { isNil } from "ramda";
import moment from "moment";

import { getResultsUrl } from "./results";
import Range from "./Range";
import ReadBar from "./ReadBar";
import "./Sample.css";

const MISSING_VALUE = "N/A";

const PDF_LANGUAGE = {
  "en": "English",
  "de": "Deutsch",
};

/**
 * If the number of reads fed into classification is less than this, display a
 * warning message
 */
const REQUIRED_READS = 4000;

const getDetails = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const patchDetails = async (sampleName, details) => {
  try {
    const response = await API.patch("mgp", `/samples/${sampleName}`, {
      body: details,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getStats = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}/stats`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getFastqStats = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}/fastq-stats`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getMetadata = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}/metadata`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getBacterialPhyla = async (sampleName) => {
  try {
    const response = await API.get(
      "mgp",
      `/samples/${sampleName}/bacterial-phyla`
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const getEnterotype = async (sampleName) => {
  try {
    const response = await API.get("mgp", `/samples/${sampleName}/enterotype`);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const Sample = (props) => {
  const { sampleName } = useParams();
  const [resultsUrl, setResultsUrl] = useState(undefined);
  const [resultsCsvUrl, setResultsCsvUrl] = useState(undefined);
  const [generaUrl, setGeneraUrl] = useState(undefined);
  const [speciesUrl, setSpeciesUrl] = useState(undefined);
  const [phylaUrl, setPhylaUrl] = useState(undefined);
  const [classesUrl, setClassesUrl] = useState(undefined);
  const [ordersUrl, setOrdersUrl] = useState(undefined);
  const [familiaeUrl, setFamiliaeUrl] = useState(undefined);
  const [stats, setStats] = useState(undefined);
  const [fastqStats, setFastqStats] = useState(undefined);
  const [metadata, setMetadata] = useState(undefined);
  const [bacterialPhyla, setBacterialPhyla] = useState(undefined);
  const [enterotype, setEnterotype] = useState(undefined);
  const [isPdfLoading, setPdfLoading] = useState(false);
  const [labels, setLabels] = useState([
    "Shannon" /*, "ACE", "Chao1", "Jackknife", "Simpson", "NPShannon"*/,
  ]);
  const [details, setDetails] = useState({});
  const setSubmitter = (submitter) =>
    setDetails({ ...details, ...{ submitter } });
  const setInfo = (info) => setDetails({ ...details, ...{ info } });
  const [isEditingSubmitter, setEditingSubmitter] = useState(false);
  const [isEditingInfo, setEditingInfo] = useState(false);
  const [pdfLang, setPdfLang] = useState("en");
  const handleMore = (event) => {
    event.preventDefault();
    if (labels.length === 1) {
      setLabels([
        "Shannon",
        "ACE",
        "Chao1",
        "Jackknife",
        "Simpson",
        "NPShannon",
      ]);
    } else {
      setLabels([
        "Shannon" /*, "ACE", "Chao1", "Jackknife", "Simpson", "NPShannon"*/,
      ]);
    }
  };
  const fetchResultsUrl = async (sampleName) =>
    setResultsUrl(
      await getResultsUrl(
        sampleName,
        "output.txt",
        `${sampleName}_species_abundance.txt`
      )
    );
  const fetchResultsCsvUrl = async (sampleName) =>
    setResultsCsvUrl(
      await getResultsUrl(
        sampleName,
        "results.csv",
        `${sampleName}_species_abundance.csv`
      )
    );
  const fetchGeneraUrl = async (sampleName) =>
    setGeneraUrl(await getResultsUrl(sampleName, "output_genera.png"));
  const fetchSpeciesUrl = async (sampleName) =>
    setSpeciesUrl(await getResultsUrl(sampleName, "output_species.png"));
  const fetchPhylaUrl = async (sampleName) =>
    setPhylaUrl(await getResultsUrl(sampleName, "output_phyla.png"));
  const fetchClassesUrl = async (sampleName) =>
    setClassesUrl(await getResultsUrl(sampleName, "output_classes.png"));
  const fetchOrdersUrl = async (sampleName) =>
    setOrdersUrl(await getResultsUrl(sampleName, "output_orders.png"));
  const fetchFamiliaeUrl = async (sampleName) =>
    setFamiliaeUrl(await getResultsUrl(sampleName, "output_familiae.png"));
  const fetchStats = async (sampleName) => setStats(await getStats(sampleName));
  const fetchFastqStats = async (sampleName) =>
    setFastqStats(await getFastqStats(sampleName));
  const fetchMetadata = async (sampleName) =>
    setMetadata(await getMetadata(sampleName));
  const fetchDetails = async (sampleName) =>
    setDetails(await getDetails(sampleName));
  const fetchBacterialPhyla = async (sampleName) =>
    setBacterialPhyla(await getBacterialPhyla(sampleName));
  const fetchEnterotype = async (sampleName) =>
    setEnterotype(await getEnterotype(sampleName));
  const downloadPdf = async (event) => {
    setPdfLoading(true);
    event.preventDefault();
    const blob = await API.get("mgp", `/samples/${sampleName}/pdf`, {
      queryStringParameters: {
        lang: pdfLang,
      },
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
    saveAs(blob, `${sampleName}.pdf`);
    setPdfLoading(false);
  };
  const handlePdfLangSelected = (lang) => {
    setPdfLang(lang);
  };
  const handleSubmitterChanged = (event) => {
    event.preventDefault();
    setSubmitter(event.target.value);
  };
  const handleSubmitterBlurred = async (event) => {
    setEditingSubmitter(false);
    await patchDetails(sampleName, details);
  };
  const handleSubmitterEditClicked = (event) => {
    setEditingSubmitter(true);
    document.getElementById("submitter").focus();
    document.getElementById("submitter").select();
  };
  const handleSubmitterSave = async (event) => {
    setEditingSubmitter(false);
    await patchDetails(sampleName, details);
  };
  const handleSubmitterKeyPress = async (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      setEditingSubmitter(false);
      await patchDetails(sampleName, details);
    }
  };

  const handleInfoChanged = (event) => {
    event.preventDefault();
    setInfo(event.target.value);
  };
  const handleInfoBlurred = async (event) => {
    setEditingInfo(false);
    await patchDetails(sampleName, details);
  };
  const handleInfoEditClicked = (event) => {
    setEditingInfo(true);
    document.getElementById("info").focus();
    document.getElementById("info").select();
  };
  const handleInfoSave = async (event) => {
    setEditingInfo(false);
    await patchDetails(sampleName, details);
  };
  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          fetchResultsUrl(sampleName),
          fetchResultsCsvUrl(sampleName),
          fetchDetails(sampleName),
          fetchStats(sampleName),
          fetchFastqStats(sampleName),
          fetchMetadata(sampleName),
          fetchGeneraUrl(sampleName),
          fetchSpeciesUrl(sampleName),
          fetchPhylaUrl(sampleName),
          fetchClassesUrl(sampleName),
          fetchOrdersUrl(sampleName),
          fetchFamiliaeUrl(sampleName),
          fetchBacterialPhyla(sampleName),
          fetchEnterotype(sampleName),
        ]);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
  }, [sampleName]);
  return (
    <>
      {stats && <div className="float-right">
        <Dropdown as={ButtonGroup}>
          <Button
            variant="primary"
            disabled={isPdfLoading}
            onClick={downloadPdf}
          >
            {isPdfLoading ? <FaSpinner className="icon-spin" /> : <FaRegFilePdf />}{" "}
            Download PDF
          </Button>
          <Dropdown.Toggle split variant="primary">
            {PDF_LANGUAGE[pdfLang]}&nbsp;&nbsp;
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              Object.keys(PDF_LANGUAGE).map((lang) => 
                <Dropdown.Item key={lang}
                  active={lang === pdfLang}
                  eventKey={lang}
                  onSelect={handlePdfLangSelected}>
                  {PDF_LANGUAGE[lang]}
                </Dropdown.Item>)
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>}
      <h3>Sample {sampleName}</h3>
      {details && (
        <>
          <section>
            <Table>
              <tbody>
                <tr className="d-flex">
                  <td className="col-2">Sample ID:</td>
                  <td className="col-6">{sampleName}</td>
                  <td className="col-1"></td>
                </tr>
                <tr className="d-flex">
                  <td className="col-2">Date of Analysis:</td>
                  <td className="col-6">
                    {details &&
                      details.date &&
                      moment(details.date).format("YYYY-MM-DD")}
                  </td>
                  <td className="col-1"></td>
                </tr>
                {metadata && metadata.MGP_VERSION && (
                <tr className="d-flex">
                  <td className="col-2">Pipeline version:</td>
                  <td className="col-6">
                    {metadata &&
                      metadata.MGP_VERSION}
                  </td>
                  <td className="col-1"></td>
                </tr>)}
                <tr className="d-flex">
                  <td className="col-2">Submitter:</td>
                  <td className="col-6">
                    <input
                      id="submitter"
                      type="text"
                      size="50"
                      name="submitter"
                      maxLength="60"
                      style={{ width: "100%" }}
                      readOnly={!isEditingSubmitter}
                      value={(details && details.submitter) || ""}
                      onChange={handleSubmitterChanged}
                      onBlur={handleSubmitterBlurred}
                      onKeyPress={handleSubmitterKeyPress}
                    />
                  </td>
                  <td className="col-1">
                    {!isEditingSubmitter ? (
                      <Button
                        variant="Light"
                        title="Edit"
                        onClick={handleSubmitterEditClicked}
                      >
                        <FaEdit />
                      </Button>
                    ) : (
                      <Button
                        variant="Light"
                        title="Save"
                        onClick={handleSubmitterSave}
                      >
                        <FaSave />
                      </Button>
                    )}
                  </td>
                </tr>
                <tr className="d-flex">
                  <td className="col-2">Additional Information:</td>
                  <td className="col-6">
                    <textarea
                      id="info"
                      type="textarea"
                      name="info"
                      rows="3"
                      cols="50"
                      maxLength="256"
                      style={{ resize: "none", width: "100%" }}
                      readOnly={!isEditingInfo}
                      value={(details && details.info) || ""}
                      onChange={handleInfoChanged}
                      onBlur={handleInfoBlurred}
                    />
                  </td>
                  <td className="col-1">
                    {!isEditingInfo ? (
                      <Button
                        variant="Light"
                        title="Edit"
                        onClick={handleInfoEditClicked}
                      >
                        <FaEdit />
                      </Button>
                    ) : (
                      <Button
                        variant="Light"
                        title="Save"
                        onClick={handleInfoSave}
                      >
                        <FaSave />
                      </Button>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </section>
          <section>
            {fastqStats && <p>Total reads: {fastqStats[0].num_seqs}</p>}
            <p>Reads passing QC: {stats !== null ? stats && stats["Number of reads"].value : 0}</p>
            {stats && stats["Number of reads"].value < REQUIRED_READS && (
              <div className="alert alert-warning" role="alert" style={{width: 400}}>
                Warning: Small number of merged reads (&lt;{REQUIRED_READS}) found after QC. Low accuracy and sensitivity, especially for low abundant species, might be observed.
              </div>
            )}
            {(stats === null || (stats && stats["Number of reads"].value <= 0)) && (
              <div className="alert alert-danger" role="alert" style={{width: 400}}>
                No merged reads found after QC. Results are unavailable.
              </div>
            )}
            {stats && (
            <>
              <p>
                <ReadBar
                  width={400}
                  height={20}
                  value={
                    (stats["Number of reads"].value -
                      stats["Number of unclassified reads"].value) /
                    stats["Number of reads"].value
                  }
                />
              </p>
              <p>
                Reads passing QC identified at species level:{" "}
                {(
                  (100 *
                    (stats["Number of reads"].value -
                      stats["Number of unclassified reads"].value)) /
                  stats["Number of reads"].value
                ).toFixed(1)}
                %
              </p>
            </>)}
          </section>
          {stats && <section>
            <h4>Diversity</h4>
            <Table>
              <tbody>
                {labels.map((stat) => (
                  <tr className="d-flex" key={stats[stat].statistic}>
                    <td className="col-2">{stats[stat].statistic}</td>
                    <td className="col-1">
                      {isNil(stats[stat].value)
                        ? MISSING_VALUE
                        : stats[stat].value.toFixed(1)}
                    </td>
                    <td className="col-3">
                      {stat === "Shannon" && !isNil(stats[stat].value) && (
                        <Range
                          value={stats[stat].value}
                          dispMin={0}
                          refMin={4}
                          refMax={10}
                          dispMax={10}
                          delta={0.1}
                        />
                      )}
                    </td>
                    <td className="col-6">
                      {stat === "Shannon" && (
                        <dl className="compact">
                          <dt>
                            <small>&lt; 3</small>
                          </dt>{" "}
                          <dd>
                            <small>limited diversity</small>
                          </dd>
                          <dt>
                            <small>3–4</small>
                          </dt>{" "}
                          <dd>
                            <small>medium diversity</small>
                          </dd>
                          <dt>
                            <small>&gt; 4</small>
                          </dt>{" "}
                          <dd>
                            <small>good diversity</small>
                          </dd>
                        </dl>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="d-flex">
                  <td className="col-12" colSpan="4">
                    <a href={`#/samples/${sampleName}`} onClick={handleMore}>
                      {labels.length === 1 ? (
                        <>
                          <FaCaretRight />
                          Show more
                        </>
                      ) : (
                        <>
                          <FaCaretUp />
                          Show less
                        </>
                      )}
                    </a>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </section>}
        </>
      )}
      {stats && stats["F/B ratio"] && (
        <section>
          <h4>Firmicutes/Bacteroidetes</h4>
          <Table>
            <tbody>
              <tr className="d-flex">
                <td className="col-2">{stats["F/B ratio"].statistic}</td>
                <td className="col-1">
                  {isNil(stats["F/B ratio"].value)
                    ? MISSING_VALUE
                    : stats["F/B ratio"].value.toFixed(1)}
                </td>
                <td className="col-3">
                  {!isNil(stats["F/B ratio"].value) && (
                    <Range
                      value={stats["F/B ratio"].value}
                      dispMin={0}
                      refMin={0}
                      refMax={1.5}
                      dispMax={5}
                      delta={0.2}
                    />
                  )}
                </td>
                <td className="col-6">
                  <dl className="compact">
                    <dt>
                      <small>≤ 1.5</small>
                    </dt>{" "}
                    <dd>
                      <small>balanced microbiome composition</small>
                    </dd>
                    <dt>
                      <small>1.5–3</small>
                    </dt>{" "}
                    <dd>
                      <small>imbalanced microbiome composition</small>
                    </dd>
                    <dt>
                      <small>≥ 3</small>
                    </dt>{" "}
                    <dd>
                      <small>unfavorable microbiome composition</small>
                    </dd>
                  </dl>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr className="d-flex">
                <td className="col-12" colSpan="4"></td>
              </tr>
            </tfoot>
          </Table>
        </section>
      )}
      {enterotype && (
        <section>
          <h4>
            Enterotype{" "}
            {enterotype.enterotype && !enterotype.ambiguous && (
              <span
                style={{
                  border: "1px solid",
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                }}
              >
                {enterotype.enterotype}
              </span>
            )}
          </h4>
          {enterotype.enterotype &&
            !enterotype.ambiguous &&
            (String(enterotype.enterotype) === "1" ? (
              <span>Bacteroides-type</span>
            ) : String(enterotype.enterotype) === "2" ? (
              <span>Prevotella-type</span>
            ) : String(enterotype.enterotype) === "3" ? (
              <span>Ruminococcus-type</span>
            ) : (
              <></>
            ))}
          {enterotype.ambiguous && (
            <span>Unambiguous assignment of enterotype not possible</span>
          )}
        </section>
      )}
      {bacterialPhyla && (
        <section>
          <h4>Bacterial Phyla</h4>
          <table>
            <thead>
              <tr>
                <th>Phylum</th>
                <th>Sample Value [%]</th>
                <th></th>
                <th>Reference Range [%]</th>
              </tr>
            </thead>
            <tbody>
              {bacterialPhyla.map((element, i) => (
                <tr key={`bacterial-phylum-${i}`}>
                  <td>{element["phylum"]}</td>
                  <td>{element["proportion_all(%)"].toFixed(2)}</td>
                  <td>
                    <Range
                      value={element["proportion_all(%)"]}
                      dispMin={element["disp_min"]}
                      refMin={element["ref_min"]}
                      refMax={element["ref_max"]}
                      dispMax={element["disp_max"]}
                    />
                  </td>
                  <td>
                    {element.hasOwnProperty("ref_min") &&
                      element.hasOwnProperty("ref_max") &&
                      `${element["ref_min"]} - ${element["ref_max"]}`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      )}
      {(stats && (resultsUrl || resultsCsvUrl)) && (
        <section>
          <h4>Species abundance</h4>
          <a
            href={`#/samples/${sampleName}/abundance`}
            title="View species abundance table"
          >
            <FaTable /> Table
          </a>{" "}
          |{" "}
          {resultsUrl ? (
            <a
              href={resultsUrl}
              title="Download species abundance table as a tab-separated text file"
            >
              <FaFile /> Download
            </a>
          ) : (
            <a
              href={resultsCsvUrl}
              title="Download species abundance table as a comma-separated file"
            >
              <FaFile /> Download
            </a>
          )}{" "}
          |{" "}
          <a
            href={`#/samples/${sampleName}/diagram`}
            title="View interactive abundance diagram"
          >
            <FaChartPie /> Diagram
          </a>
        </section>
      )}
      {generaUrl && (
        <section>
          <h4>Genus and Species level composition</h4>
          <Tabs defaultActiveKey="genus">
            <Tab eventKey="genus" title="Genus">
              {metadata && metadata["MGP_FIG_NOTITLE"] && <div className="figtitle">Proportion of QC passing reads<br />Genus</div> }
              <img src={generaUrl} alt="Genus abundance" />
            </Tab>
            <Tab eventKey="species" title="Species">
            {metadata && metadata["MGP_FIG_NOTITLE"] && <div className="figtitle">Proportion of QC passing reads<br />Species</div> }
              <img src={speciesUrl} alt="Species abundance" />
            </Tab>
          </Tabs>
        </section>
      )}
      {phylaUrl && (
        <section>
          <h4>Phylum/Class/Order/Family level composition</h4>
          <Tabs defaultActiveKey="phylum">
            <Tab eventKey="phylum" title="Phylum">
              {metadata && metadata["MGP_FIG_NOTITLE"] && <div className="figtitle">Proportion of QC passing reads<br />Phylum</div> }
              <img src={phylaUrl} alt="Phylum abundance" />
            </Tab>
            <Tab eventKey="class" title="Class">
              {metadata && metadata["MGP_FIG_NOTITLE"] && <div className="figtitle">Proportion of QC passing reads<br />Class</div> }
              <img src={classesUrl} alt="Class abundance" />
            </Tab>
            <Tab eventKey="order" title="Order">
              {metadata && metadata["MGP_FIG_NOTITLE"] && <div className="figtitle">Proportion of QC passing reads<br />Order</div> }
              <img src={ordersUrl} alt="Order abundance" />
            </Tab>
            <Tab eventKey="family" title="Family">
              {metadata && metadata["MGP_FIG_NOTITLE"] && <div className="figtitle">Proportion of QC passing reads<br />Family</div> }
              <img src={familiaeUrl} alt="Family abundance" />
            </Tab>
          </Tabs>
        </section>
      )}
    </>
  );
};

export default Sample;
