import { API } from "aws-amplify";

const getResultsUrl = async (sampleName, path, filename) => {
  try {
    const init = {};
    if (filename) {
      init.queryStringParameters = {
        filename: filename.replace(/[^A-Za-z0-9._-]+/g, "-"),
      };
    }
    const response = await API.get(
      "mgp",
      `/samples/${sampleName}/file/${path}`,
      init
    );
    return response.url;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

export { getResultsUrl };
