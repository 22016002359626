import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BootstrapTable from "react-bootstrap-table-next";
import {
  FaSpinner,
  FaTrashAlt,
  FaCaretUp,
  FaCaretDown,
  FaCaretRight,
} from "react-icons/fa";
import { API } from "aws-amplify";
import moment from "moment";
import { without, sort, prop, ascend } from "ramda";

import useInterval from "./hooks/useInterval";

const getSortFromUrl = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.hash.split('?')[1]);
  const sortField = params.get('sort');
  const sortOrder = params.get('order');

  if (!sortField || !sortOrder) {
    return [{ // default sort if not in URL
      dataField: "date",
      order: "desc",
    }]; 
  }

  return [{
    dataField: sortField,
    order: sortOrder
  }];
};

const updateUrl = (field, order) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.hash.split('?')[1]);

  params.set('sort', field);
  params.set('order', order);

  url.hash = url.hash.split('?')[0] + '?' + params.toString();
  window.history.replaceState({}, '', url.toString());
};

const Samples = () => {
  const [samples, setSamples] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const initialSort = getSortFromUrl();
  const loadSamples = async () => {
    const newSamples = await API.get("mgp", "/samples");
    setSamples(sort(ascend(prop("date")), newSamples));
  };
  const handleDelete = async (event) => {
    event.preventDefault();
    setShowConfirmDelete(true);
  };
  const handleConfirmCancel = () => {
    setShowConfirmDelete(false);
  };
  const handleConfirm = async (event) => {
    event.preventDefault();
    try {
      for (const sampleName of selected) {
        await API.del("mgp", `/samples/${sampleName}`);
      }
      setSelected([]);
      await loadSamples();
    } catch (err) {
      alert(err.message);
    }
    setShowConfirmDelete(false);
  };
  useEffect(() => {
    (async () => {
      try {
        await loadSamples();
      } catch (err) {
        alert(err);
      }
    })();
  }, []);
  const sortCaret = (order) =>
    order === "asc" ? (
      <FaCaretUp />
    ) : order === "desc" ? (
      <FaCaretDown />
    ) : (
      <FaCaretRight style={{ visibility: "hidden" }} />
    );
  useInterval(() => {
    // refresh sample list if there are samples in 'Processing' state
    (async () => {
      try {
        if (
          samples &&
          samples.find((sample) => sample.status === "Processing")
        ) {
          await loadSamples();
        }
      } catch (err) {
        alert(err);
      }
    })();
  }, 10000);
  return (
    <>
      {selected.length > 0 && (
        <Button
          variant="secondary"
          className="float-right"
          disabled={selected.length === 0}
          onClick={handleDelete}
        >
          <FaTrashAlt /> Delete selected
        </Button>
      )}
      <Modal show={showConfirmDelete} backdrop="static">
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Delete selected {selected.length}{" "}
            {selected.length === 1 ? "sample" : "samples"}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <h3>Sample overview</h3>
      <BootstrapTable
        bootstrap4
        striped
        bordered={false}
        keyField="name"
        data={samples}
        columns={[
          {
            dataField: "name",
            text: "Sample",
            sort: true,
            onSort: (field, order) => updateUrl(field, order),
            sortCaret,
            formatter: (name, sample) =>
              sample.status === "Processing" ? (
                name
              ) : (
                <a href={`#/samples/${name}`}>{name}</a>
              ),
          },
          {
            dataField: "files",
            text: "Files",
            sort: true,
            sortCaret,
            onSort: (field, order) => updateUrl(field, order),
            formatter: (files) =>
              files && files.length > 0 && files.join(" / "),
          },
          {
            dataField: "date",
            text: "Date",
            sort: true,
            sortCaret,
            onSort: (field, order) => updateUrl(field, order),
            formatter: (date) =>
              date && moment(date).format("YYYY-MM-DD HH:mm"),
          },
          {
            dataField: "status",
            text: "Status",
            sort: true,
            sortCaret,
            onSort: (field, order) => updateUrl(field, order),
            formatter: (status) => (
              <>
                {status}{" "}
                {status === "Processing" && <FaSpinner className="icon-spin" />}
              </>
            ),
          },
        ]}
        defaultSorted={initialSort}
        selectRow={{
          mode: "checkbox",
          hideSelectAll: true,
          selected: selected,
          onSelect: (row, isSelect) => {
            if (isSelect) { // selected.includes(row.name)
              setSelected(selected.concat(row.name));
            } else {
              setSelected(without([row.name], selected));
            }
          },
        }}
      />
      <p>
        Start analysis by <a href="#/upload">uploading</a> your fastq files.
      </p>
    </>
  );
};

export default Samples;
