import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { API } from "aws-amplify";

const loadSpeciesAbundance = async sampleName => {
  try {
    return await API.get("mgp", `/samples/${sampleName}/species_abundance`);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};

const SpeciesAbundance = props => {
  const { sampleName } = useParams();
  const [speciesAbundance, setSpeciesAbundance] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const speciesAbundance = await loadSpeciesAbundance(sampleName);
        setSpeciesAbundance(speciesAbundance);
      } catch (error) {
        console.log(error);
        alert(error);
      }
    })();
  }, [sampleName]);
  return (
    <>
      <h3>Sample {sampleName} - Species abundance</h3>
      {speciesAbundance && (
        <section>
          <Table striped hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Taxonomy ID</th>
                <th>Lineage</th>
                <th>Reads</th>
                <th>Proportion</th>
              </tr>
            </thead>
            <tbody>
              {speciesAbundance.map(species => (
                <tr key={species.taxid || "UNKNOWN"}>
                  <td>
                    {species.taxid ? (
                      <a
                        href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${species.taxid}`}
                        target="taxonomy"
                        rel="noopener noreferrer"
                      >
                        {species.name}
                      </a>
                    ) : (
                      <span>UNKNOWN</span>
                    )}
                  </td>
                  <td>{species.taxid}</td>
                  <td>
                    {species.taxid && species.lineage &&
                      Object.entries(species.lineage)
                        .map(([rank, name]) => (
                          <a
                            key={name}
                            href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?name=${name}`}
                            title={rank}
                            target="taxonomy"
                            rel="noopener noreferrer"
                          >
                            {name}
                          </a>
                        ))
                        .reduce((a, e) => [a, " > ", e])}
                  </td>
                  <td>{species.count}</td>
                  <td>
                    {species["proportion_all(%)"] &&
                      `${species["proportion_all(%)"].toFixed(1)}%`}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>
      )}
    </>
  );
};

export default SpeciesAbundance;
