import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import { API, Storage } from "aws-amplify";
import {
  any,
  assoc,
  chain,
  clone,
  update,
  remove,
  isEmpty,
  isNil,
} from "ramda";
import { FaCloudUploadAlt, FaSpinner } from "react-icons/fa";
import { intersection } from "ramda";

import { arrangeSamples } from "./fileNames";
import { loadNumSamples } from "./activationCodes";

const isMac = () => navigator && /(Mac|iPhone|iPod|iPad)/i.test(navigator.userAgent);

const hasDuplicate = (arr, i) => remove(i, 1, arr).includes(arr[i]);

const hasDuplicates = (arr) => new Set(arr).size !== arr.length;

const isInvalidSampleName = (name) => isNil(name) || isEmpty(name);

const hasInvalidSampleNames = (samples) => {
  const sampleNames = samples.map((sample) => sample.name);
  return any(isInvalidSampleName, sampleNames) || hasDuplicates(sampleNames);
};

const Upload = ({ history }) => {
  const [samples, setSamples] = useState([]);

  const [numSamples, setNumSamples] = useState(null);

  const [isPaired, setPaired] = useState(true);

  const [isUploading, setUploading] = useState(false);

  const [progresses, setProgresses] = useState(null);

  const [showConfirm, setShowConfirm] = useState(false);

  const [existingSamples, setExistingSamples] = useState([]);

  const overwrite = intersection(
    samples.map((s) => s.name),
    existingSamples.map((s) => s.name)
  );

  const loadExistingSamples = async () => {
    setExistingSamples(await API.get("mgp", "/samples"));
  };

  const s3Upload = async (sampleNum, updateProgress) => {
    const sample = samples[sampleNum];
    if (overwrite.includes(sample.name)) {
      try {
        await API.del("mgp", `/samples/${sample.name}`);
      } catch (e) {
        console.error(e);
      }
    }
    try {
      for (let fileNum = 0; fileNum < sample.files.length; fileNum++) {
        const file = sample.files[fileNum];
        const filename = `${sample.name}/${file.name}`;
        await Storage.vault.put(filename, file, {
          contentType: file.type,
          progressCallback: (progress) => updateProgress(fileNum, progress),
        });
        // TODO: consider also storing some potentially useful information in file metadata attributes
      }
      await API.post("mgp", `/samples/${sample.name}/start_analysis`);
    } catch (err) {
      alert(err.message);
      throw err;
    }
  };

  const upload = async () => {
    setUploading(true);
    const updatedProgresses = samples.map((sample) =>
      sample.files.map(() => 0)
    );
    for (let sampleNum = 0; sampleNum < samples.length; sampleNum++) {
      await s3Upload(sampleNum, (fileNum, progress) => {
        const uploaded = progress.loaded / progress.total;
        updatedProgresses[sampleNum][fileNum] = uploaded;
        setProgresses(clone(updatedProgresses));
      });
    }
    setUploading(false);
    history.push("/samples");
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    setShowConfirm(false);
    await upload();
  };

  const handleConfirmCancel = (event) => {
    event.preventDefault();
    setShowConfirm(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowConfirm(true);
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const allFiles = Array.from(event.target.files);
    console.log(allFiles);
    let wantPaired = isPaired;
    if (wantPaired && allFiles.length % 2 !== 0) {
      // can't pair odd number of files, reset pairing to false
      wantPaired = false;
      setPaired(wantPaired);
    }
    const newSamples = arrangeSamples(wantPaired, allFiles);
    setSamples(newSamples);
  };

  const handlePairedChange = (event) => {
    const wantPaired = event.target.checked;
    if (isPaired !== wantPaired) {
      setPaired(wantPaired);
      if (samples.length > 0) {
        const allFiles = chain((sample) => sample.files, samples);
        const newSamples = arrangeSamples(wantPaired, allFiles);
        setSamples(newSamples);
      }
    }
  };

  const setSampleName = (index, event) => {
    const value = event.target.value;
    console.log(`set sample ${index} name to ${value}`);
    setSamples(update(index, assoc("name", value, samples[index]), samples));
  };

  const getSampleProgress = (sampleIndex) =>
    samples[sampleIndex].files.reduce(
      (progress, file, fileIndex) => {
        progress.total += file.size;
        const proportionUploaded =
          progresses &&
          progresses[sampleIndex] &&
          progresses[sampleIndex][fileIndex];
        if (proportionUploaded) {
          progress.loaded += file.size * proportionUploaded;
        }
        return progress;
      },
      { total: 0, loaded: 0 }
    );

  const getTotalProgress = () =>
    samples.reduce(
      (progress, sample, sampleIndex) => {
        const sampleProgress = getSampleProgress(sampleIndex);
        if (sampleProgress) {
          progress.total += sampleProgress.total;
          progress.loaded += sampleProgress.loaded;
        }
        return progress;
      },
      { total: 0, loaded: 0 }
    );

  const getTotalProgressProportion = () => {
    const progress = getTotalProgress();
    return progress.total > 0 && progress.loaded / progress.total;
  };

  useEffect(() => {
    (async () => {
      try {
        const numSamples = await loadNumSamples();
        setNumSamples(numSamples);
      } catch (err) {
        alert(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await loadExistingSamples();
      } catch (err) {
        alert(err);
      }
    })();
  }, []);

  return (
    <>
      <h3>Upload</h3>
      <p>You have {numSamples} samples remaining.</p>
      <Form onSubmit={handleSubmit}>
        <h4>Upload fastq files</h4>
        <Form.Group controlId="paired">
          <Form.Check
            type="checkbox"
            label="Paired input files"
            checked={isPaired}
            onChange={handlePairedChange}
            disabled={!isPaired && samples.length % 2 !== 0}
          />
        </Form.Group>
        {samples.length > 0 && (
          <Table>
            {isPaired ? (
              <colgroup>
                <col width="30%" />
                <col width="35%" />
                <col width="35%" />
              </colgroup>
            ) : (
              <colgroup>
                <col width="30%" />
                <col width="70%" />
              </colgroup>
            )}
            <thead>
              <tr>
                <th>Sample name</th>
                <th>Fastq file{isPaired && " 1"}</th>
                {isPaired && <th>Fastq file 2</th>}
              </tr>
            </thead>
            <tbody>
              {samples.map((sample, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      style={{ width: "100%" }}
                      value={sample.name}
                      onChange={(event) => setSampleName(index, event)}
                      disabled={isUploading}
                    />
                    {(isInvalidSampleName(sample.name) ||
                      hasDuplicate(
                        samples.map((sample) => sample.name),
                        index
                      )) && (
                      <Alert variant="danger">
                        Please enter a unique name.
                      </Alert>
                    )}
                    {existingSamples
                      .map((s) => s.name)
                      .includes(sample.name) && (
                      <Alert variant="warning">
                        A sample with the name "{sample.name}" already exists.
                        The results will be overwritten.
                      </Alert>
                    )}
                  </td>
                  <td>
                    {sample.files[0].name}
                    {progresses && (
                      <ProgressBar now={progresses[index][0] * 100} />
                    )}
                  </td>
                  {isPaired && (
                    <td>
                      {sample.files[1].name}
                      {progresses && (
                        <ProgressBar now={progresses[index][1] * 100} />
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Form.Group controlId="file">
          <Form.Control
            type="file"
            onChange={handleFileChange}
            multiple
            accept={isMac() ? ".fastq,.fastq.gz,.fq,.fq.gz,.gz,application/gzip" : "fastq,.fastq.gz,.fq,.fq.gz"}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          disabled={
            isUploading ||
            samples.length === 0 ||
            numSamples < samples.length ||
            hasInvalidSampleNames(samples)
          }
        >
          {isUploading ? (
            <FaSpinner className="icon-spin" />
          ) : (
            <FaCloudUploadAlt />
          )}{" "}
          Upload
        </Button>
      </Form>
      <Modal show={showConfirm} backdrop="static">
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please confirm that you want to start upload and analysis. This will
            cause {samples.length} {samples.length === 1 ? "sample" : "samples"}{" "}
            to be deducted from your account balance.
          </p>
          {overwrite.length > 0 && (
            <Alert variant="warning">
              The results for {overwrite.length}{" "}
              {overwrite.length === 1 ? "sample" : "samples"} will be
              overwritten.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Upload and Analyze
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isUploading} backdrop="static">
        <Modal.Header>
          <Modal.Title>Uploading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please wait while the files are being uploaded.</p>
          <ProgressBar now={getTotalProgressProportion() * 100} />
          <p>
            As the upload for each sample completes, the analysis for the sample
            is started.
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(Upload);
